import React, { useEffect, useState, useMemo, useCallback } from "react"
import { FloatingBanner } from "./consent";
import Cookies from "universal-cookie"
//import PropTypes from "prop-types"

export function ConsentForm({ color }) {
    const [decisionMade, setDecisionMade] = useState(true) // start with true to avoid flashing
    const cookies = useMemo(() => new Cookies(), []);

    function gtag() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(arguments);
    }

    const sendConsent = useCallback((consent) => {
        gtag('consent', 'default', consent);
    }, []);

    useEffect(() => {
        if (cookies.get("seiglie") !== undefined) {
            setDecisionMade(true)
        } else {
            setDecisionMade(false)
        }
    }, [cookies, setDecisionMade, sendConsent])

    const handleDecision = (outcome) => {
        
        const consent = {
            'ad_storage': outcome,
            'analytics_storage': outcome,
            'ad_user_data': outcome,
            'ad_personalization': outcome,
        }
        
        //console.log(consent);
        cookies.set("_ga_con", consent, {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            path: "/",
            domain: ".seiglie.es"
        })
        sendConsent(consent)
        setDecisionMade(true)
    }

    return (
        decisionMade ? (
            <></>
        ) : (
            <FloatingBanner
                color={color}
                header='Cookies '
                message="This site uses cookies to better the user experience, grab a bite!"
                acceptText="Give me!"
                denyText="I'm on a diet"
                onAccept={
                    () => {
                        handleDecision("granted")
                    }
                }
                onDeny={
                    () => {
                        handleDecision("denied")
                    }
                } />
        )
    )
}

// ConsentForm.propTypes = {
//     color: PropTypes.string.isRequired,
// }

// ConsentForm.defaultProps = {
//     color: "blue",
// }