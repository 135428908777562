export const checkStorage = (item) => {
  try {
    const element = JSON.parse(localStorage.getItem(item));
    if (!element) {
      throw new Error("No item found");
    }
    if (Array.isArray(element) && element.length === 0) {
      throw new Error("item is empty");
    }
    return element;
  } catch (error) {
    // console.log('Error ---> ',error.message)
    return false;
  }
};

export const storeOnLocal = (name, obj = {}) => {
  //expiration date is 1 days from now
  if (Array.isArray(obj)) {
    obj.forEach((el) => {
      el.expDate = new Date().getTime() + 1000 * 60 * 60 * 24;
    });
  } else {
    obj.expDate = new Date().getTime() + 1000 * 60 * 60 * 24;
  }

  if (name === "article") {
    const articlesVisited = JSON.parse(localStorage.getItem("articlesVisited"));
    if (articlesVisited) {
      articlesVisited.push(obj);
      return localStorage.setItem(
        "articlesVisited",
        JSON.stringify(articlesVisited)
      );
    } else {
      return localStorage.setItem("articlesVisited", JSON.stringify([obj]));
    }
  }
  localStorage.setItem(name, JSON.stringify(obj));
};

export const clearStorage = (name) => {
  localStorage.removeItem(name);
};

export const clearAllStorage = () => {
  localStorage.clear();
};
