import React, { useContext, lazy, Suspense } from "react";
import { LatestCard } from "../components/dashboard/latest_card";
import { Context } from "../state/appContext";
import { ArticlesCard } from "../components/dashboard/article_card";
import { PacmanLoader } from "../components/pacmanLoader/pacman_loader";
const Searchbar = lazy(async () => import("../components/dashboard/searchbar"));

export const Dashboard = () => {
  const { store } = useContext(Context);
  return (
    <section className="container ">
    <Suspense fallback={<PacmanLoader/>}>
      <Searchbar />
      <section className="mx-auto row gx-3">
        <aside className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-2">
          <h3 className="latest-subtitle my-2">Latest</h3>
          {store.latest?.map((el) => (
            <LatestCard
              key={el.id}
              id={el.id}
              repository={el.repository}
              title={el.title}
              rating={el.rating}
              video={el.video}
              video_thumbnail={el.video_thumbnail}
              img={el.img}
            />
          ))}
        </aside>

        <section className="col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-10 row articles-hard-holder d-flex justify-content-center">
          {store.articles?.map((el) => (
            <ArticlesCard
              id={el.id}
              key={el.id}
              repository={el.repository}
              title={el.title}
              rating={el.rating}
              video={el.video}
              video_thumbnail={el.video_thumbnail}
              description={el.description}
              img={el.img}
            />
          ))}
        </section>
      </section>
    </Suspense>
    </section>
  );
};
