import { fetcher } from "../helpers/fetcher";

export const getCategories = async () => {
  try {
    const { data, error } = await fetcher(`/categories/all`, "GET");
    if (!data.success) {
      throw new Error(error);
    }
    if (error) throw new Error(error);
    localStorage.setItem("categories", JSON.stringify(data.payload));
    return { success: true, categories: data.payload };
  } catch (error) {}
};

export const getOneCategory = async (category) => {
  try {
    const { data, error } = await fetcher(`/categories/${category}`, "GET");
    if (!data.success) {
      throw new Error(error);
    }
    if (error) throw new Error(error);
    return { success: true, category: data.payload };
  } catch (error) {}
}