import { getArticle, getLatestArticles } from "./articleStories";
import {
  getUser,
  uploadImage,
  userLoginRegister,
  userLogout,
} from "./useStories";
import { addError, clearError } from "./errors";
import { getCategories } from "./categorieStories";

const getState = ({ getStore, getActions, setStore }) => {
  return {
    store: {
      test: "ok",
      auth: false,
      errors: [],
      categories: JSON.parse(localStorage.getItem("categories")) || [],
      articles: JSON.parse(localStorage.getItem("articles")) || [],
      tags: JSON.parse(localStorage.getItem("tags")) || [],
    },
    actions: {
      // test: () =>
      //   fetch("/api/service/test")
      //     .then((resp) => resp.json())
      //     .then((data) => console.log(data)),
      getCategories: async () => {
        try {
          const resp = await getCategories(setStore);
          if (!resp.success) throw new Error("Something went wrong");
          setStore({ categories: resp.categories });
        } catch (error) {
          console.error(error);
        }
      },
      selectCategory: (category) => {
        try {
          setStore({ selectedCategory: category });
        } catch (error) {
          console.error(error);
        }
      },
      logout: () => userLogout(setStore),
      loginRegister: async (endpoint, credentials) => {
        try {
          const res = await userLoginRegister(setStore, endpoint, credentials);
          if (!res.success) throw new Error("Something went wrong");
          setStore({ user: res.user, auth: res.auth });
          return true;
        } catch (error) {
          console.log(error);
        }
      },
      addError: (error) =>
        setStore({ errors: [...getStore().errors, addError(error)] }),
      clearError: () => setStore({ errors: clearError() }),
      getUser: async () => {
        try {
          const resp = await getUser();
          console.log(resp);

          if (!resp.success) throw new Error("Something went wrong");
          setStore({ user: resp.user, auth: resp.auth });
        } catch (error) {
          console.error(error);
        }
      },
      uploadAvatar: async (img) => {
        try {
          if (!img) throw new Error("Avatar missing");
          const res = await uploadImage(img);
          console.log(res);
          if (!res.success) throw new Error("Something went wrong");
          setStore({ user: res.user });
          return { success: true };
        } catch (error) {
          console.error(error);
        }
      },
      getLatestArticles: async () => {
        try {
          const resp = await getLatestArticles("/articles/latest");
          if (!resp.success) throw new Error();
          console.log('latest---> ', resp);
          
          setStore({ latest: resp.data });
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      },
      getArticle: async (title) => {
        try {
          const resp = await getArticle("/articles/latest/" + title);
          if (!resp.success) throw new Error();
          setStore({ article: resp.article });
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      },
      selectArticle: (selected) => {
        setStore({
          article: getStore().articles.filter((el) => el.id === selected)[0],
        });
        getActions().getArticle(getStore().article.repository);
      },
    },
  };
};

export default getState;
