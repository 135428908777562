import { storeOnLocal } from "./storageHandler";

const checkExpiration = (storeName) => {
  const item = JSON.parse(localStorage.getItem(storeName));
  if (!item) {
    return true;
  }
  if (Array.isArray(item)) {
    // Verifica si algún objeto en el array ha expirado
    return item.some(
      (obj) => !obj.expDate || new Date().getTime() > obj.expDate
    );
  } else {
    // Verifica si el objeto ha expirado
    return !item.expDate || new Date().getTime() > item.expDate;
  }
};

export const fetcher = async (
  endpoint,
  method,
  authorization,
  body,
  store = false,
  storeName = ""
) => {
  const headers = new Headers();
  if (method === "POST" || method === "PUT") {
    headers.append("Content-Type", "application/json");
  }
  if (authorization) {
    if (!localStorage.getItem("token")) {
      return { success: false, error: "User not logged in" };
    }
    headers.append("Authorization", localStorage.getItem("token"));
  }
  const opt = {
    method,
    headers,
    ...(method === "POST" || method === "PUT"
      ? { body: JSON.stringify(body) }
      : {}),
  };

  try {
    if (storeName !== "" && localStorage.getItem(storeName)) {
      if (!checkExpiration(storeName)) {
        return {
          success: true,
          data: JSON.parse(localStorage.getItem(storeName)),
        };
      }
    }
    const resp = await fetch("/api" + endpoint, opt);
    if (!resp.ok) {
      throw new Error(`Error fetching: ${resp.statusText}`);
    }
    const data = await resp.json();
    if (store) {
      storeOnLocal(storeName, data.resp);
    }
    return { success: true, data };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
